import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'app/rootReducer'

import { ItemsList } from './itemsList/ItemsList'
import { GeomsList } from './geomsList/GeomsList'
import { MaterialsList } from './materialsList/MaterialsList'
import { fetchAssets } from './assetsSlice'
import {useParams} from "react-router";


export const AssetsList = () => {
  const dispatch = useDispatch()
  let { category } = useParams();
  const {
    items,
    geoms,
    materials,
    isLoading,
    error: assetsError,
  } = useSelector((state: RootState) => state.assets)

  const { selectedItemId, selectedGeoms, selectedMaterials } = useSelector((state: RootState) => state.editor)


  useEffect(() => {
    dispatch(fetchAssets(category))
  }, [dispatch])

  if (assetsError) {
    return (
      <div>
        <h1>Something went wrong...</h1>
        <div>{assetsError.toString()}</div>
      </div>
    )
  }

  let renderedList = isLoading ? (
    <h3>Loading...</h3>
  ) : (
    <div>
      <ItemsList items={items} selectedItemId={selectedItemId}/>
      <GeomsList geoms={geoms} selectedGeoms={selectedGeoms}/>
      <MaterialsList materials={materials} selectedMaterials={selectedMaterials}/>
    </div>
  )

  return (
    <div id="issue-list-page">
      {renderedList}
    </div>
  )
}
