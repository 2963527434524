import { combineReducers } from '@reduxjs/toolkit'

import assetsReducer from 'features/assetsList/assetsSlice'
import editorReducer from 'features/editor/editorSlice'
import textureReducer from 'features/textureImageForm/textureSlice'
import previewsReducer from 'features/previews/previewsSlice'
import basketReducer from 'features/basket/basketSlice'
import authReducer from 'features/auth/authSlice'

const rootReducer = combineReducers({
  assets: assetsReducer,
  editor: editorReducer,
  textures: textureReducer,
  previews: previewsReducer,
  basket: basketReducer,
  auth: authReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
