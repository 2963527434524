import React from 'react'

import { Item } from 'api/githubAPI'
import { ItemDetails } from './ItemDetails'
import {useDispatch, useSelector} from 'react-redux'
import { setItem } from '../../editor/editorSlice'
import {RootState} from "../../../app/rootReducer";


interface Props {
  items: {
    parentIds: number[]
    entities: { [key: number]: Item }
  },
  selectedItemId: number | null
}

export const ItemsList = ({ items: { parentIds, entities }, selectedItemId }: Props) => {
  const dispatch = useDispatch()

  const {
    editor_config: {editor_heading}
  } = useSelector((state: RootState) => state.editor)

  const setEditorItem = (item: Item) => {
    dispatch(setItem(item))
  }

  const renderedItems = parentIds.map(id => (
    <div className="col-sm-3" key={id} onClick={() => setEditorItem(entities[id])}>
      <ItemDetails selected={selectedItemId===id} {...entities[id]} />
    </div>
  ))

  return <div>
    <h3>{ editor_heading }</h3>
    <h4>Popular configurations:</h4>
    <div className="card-group no-gutters">{renderedItems}</div>
  </div>
}
