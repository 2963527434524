import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import {getBasket, postBasketItem, deleteBasketItem, Preview} from 'api/githubAPI'
import {LoadingState, loadingInitialState, startLoading, loadingFailed} from 'utils/loadingWithError'
import {byId, uniquFieldValues} from 'utils/idFunctions'

export interface BasketItem {
  id: number,
  owner_id: number,
  preview: Preview,
  arrangement: number,
  price: number,
}

export interface Basket {
  entities: {[key: number]: BasketItem}
  ids: Array<number>
  arrangementIds: Array<number>
  totalPrice: number | null
}

interface BasketState extends LoadingState, Basket{}

const basketInitialState: BasketState = {
  entities: [],
  ids: [],
  arrangementIds: [],
  totalPrice: null,
  ...loadingInitialState
}

const basket = createSlice({
  name: 'basket',
  initialState: basketInitialState,
  reducers: {
    getBasketStart: startLoading,
    getBasketSuccess(state, { payload }: PayloadAction<{items: BasketItem[], total_price: number}>) {
      const { items: basketItems, total_price: totalPrice } = payload
      state.isLoading = false
      state.error = null

      const itemsById = byId(basketItems);
      state.entities = itemsById;
      state.ids = Object.keys(itemsById).map(Number);
      state.arrangementIds = uniquFieldValues(basketItems, 'arrangement');
      state.totalPrice = totalPrice

    },
    addBasketItem(state, { payload }: PayloadAction<{item: BasketItem, total_price: number}>) {
      state.entities[payload.item.id] = payload.item
      state.ids.push(payload.item.id)
      state.arrangementIds.push(payload.item.arrangement)
      state.isLoading = false
      state.error = null
      state.totalPrice = payload.total_price
    },
    deleteBasketItemSuccess(state, { payload }: PayloadAction<{item: BasketItem, total_price: number}>) {
      const deleteBasketItem = Object.values(state.entities).find(item => item.arrangement === payload.item.arrangement);
      if( deleteBasketItem != null ) {
        delete state.entities[deleteBasketItem.id]
        state.ids = state.ids.filter(id => id !== deleteBasketItem.id);
        state.arrangementIds = state.arrangementIds.filter(id => id !== payload.item.arrangement)
        state.isLoading = false
        state.error = null
        state.totalPrice = payload.total_price
      }
    },
    getBasketFailure: loadingFailed
  }
})

export const {
  getBasketStart,
  getBasketSuccess,
  addBasketItem,
  deleteBasketItemSuccess,
  getBasketFailure
} = basket.actions

export default basket.reducer


export const fetchBasket = (): AppThunk => async dispatch => {
  try {
    dispatch(getBasketStart())
    const basket = await getBasket()
    dispatch(getBasketSuccess(basket))
  } catch (err) {
    dispatch(getBasketFailure(err.toString()))
  }
}

export const addToBasket = (arrangementId: number): AppThunk => async dispatch => {
  try {
    dispatch(getBasketStart())
    const addedItem = await postBasketItem(arrangementId)
    dispatch(addBasketItem(addedItem))
  } catch (err) {
    dispatch(getBasketFailure(err.toString()))
  }
}

export const removeFromBasket = (arrangementId: number): AppThunk => async dispatch => {
  try {
    dispatch(getBasketStart())
    const addedItem = await deleteBasketItem(arrangementId)
    dispatch(deleteBasketItemSuccess(addedItem))
  } catch (err) {
    dispatch(getBasketFailure(err.toString()))
  }
}