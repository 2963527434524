import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {confirmEmail} from 'features/auth/authSlice'
import {useParams} from "react-router";
import {RootState} from "../app/rootReducer";


export const ConfirmEmailForm = () => {
    let { key  } = useParams();
    const dispatch = useDispatch();

    const {
        email_confirmed,
        isLoading,
        error: authError,
    } = useSelector((state: RootState) => state.auth)

    useEffect(() => {
        dispatch(confirmEmail(key));
    }, [dispatch])

    return (
        <div style={{'border': '1px solid #666', 'padding': '6px'}}>
            {email_confirmed ? <div>Email Confirmed</div> : <div>Email not confirmed</div>}
        </div>
    )
}