import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'app/rootReducer'


import {fetchBasket} from "features/basket/basketSlice";

import { useParams } from "react-router-dom";
import { LoginForm } from "components/LoginForm"

import { PollingImageAvailable} from 'features/previews/PollingImageAvailable'
import {currency} from "../../utils/formatters";
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Modal from 'react-bootstrap/Modal'
import { addToBasket, removeFromBasket } from 'features/basket/basketSlice'

export const BasketPage = () => {
    let { id: jobUUID } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [removeId, setRemoveId] = useState(0);
    const {
        ids,
        entities,
        totalPrice,
        isLoading,
        error: basketError,
    } = useSelector((state: RootState) => state.basket)

    useEffect(() => {
        dispatch(fetchBasket())
    }, [dispatch])

    if (basketError) {
        return (
            <div>
                <h1>Something went wrong...</h1>
                <div>{basketError.toString()}</div>
            </div>
        )
    }


    const handleClose = () => setShow(false);
    const handleShow = (id: number) => {
        setShow(true);
        setRemoveId(id)
    }
    const handleRemove = () => {
        setShow(false);
        dispatch(removeFromBasket(removeId))
    }

    const AreYouSureModal = () => {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton translate={null}>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to remove this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleRemove}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
            )
    }


    let renderedList = isLoading ? (
        <h3>Loading...</h3>
    ) : (
        ids.map(id => (
            <Card style={{ width: '18rem' }} key={id}>
                <Card.Body>
                    <PollingImageAvailable
                        maxTries={20}
                        delay={2000}
                        preview={entities[id].preview} />
                </Card.Body>
                <Card.Footer>
                    <div className="float-left">Price: {currency.format(entities[id].price)}</div>
                    <Button variant="warning" className="float-right" onClick={() => handleShow(entities[id].arrangement)}>
                        Remove from cart <i className="fas fa-shopping-cart"></i>
                    </Button>

                </Card.Footer>

            </Card>
        ))
    )

    return (
        <>
            <CardDeck>
                {renderedList}
                <AreYouSureModal />
            </CardDeck>
            <div>
                Total Price: {totalPrice}
            </div>
            <LoginForm />
        </>
    )
}
