import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/rootReducer'
import Button from 'react-bootstrap/Button'
import { createPreviews, EditorState } from './editorSlice'
import { useHistory } from 'react-router-dom';


export const PreviewButton = () => {
  const dispatch = useDispatch()
  const { readyForPreview, isLoading } = useSelector((state: RootState) => state.editor)
  const editorForm = useSelector((state: RootState) => state.editor)
  let history = useHistory();

  const submitCreatePreviews = (editorForm: EditorState, history: any ) => {
    console.log('clicked')
    if(!isLoading) {
      dispatch(createPreviews(editorForm, history))
    }
  }

  return (
        <Button variant="primary"
          disabled={!readyForPreview || isLoading}
          onClick={() => { submitCreatePreviews(editorForm, history) }}>
          {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> }
          {' '}Create Previews
        </Button>

  )
}