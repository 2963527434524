import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { createTexture, fetchTextures } from './textureSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setTexture } from '../editor/editorSlice'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

// https://react-dropzone.js.org/#!/Previews/1
export const TextureImageForm = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTextures())
  }, [dispatch])

  const { textures } = useSelector((state) => state.textures)
  const { selectedTextureId } = useSelector((state) => state.editor)

  const [files, setFiles] = useState([]);

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));

      let formData = new FormData();
      acceptedFiles.map(file => {
        formData.append('image_file', file)
        formData.append('file_name', file.name)
      })
      dispatch(createTexture(formData))
    }
  });

  const selectTexture = (id) => {
    dispatch(setTexture(id))
  }

  const existingThumbs = textures.map(texture => (
    <div className={`card ${texture.id === selectedTextureId ? "border-primary" : ""}`}
         style={thumb} key={texture.id} onClick={() => selectTexture(texture.id)}>
      <div style={thumbInner}>
        <img
          src={texture.url}
          style={img}
          alt={"Uploaded images"}
        />
      </div>
    </div>
  ));

  const newThumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt={"Uploaded images"}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {existingThumbs}
        {newThumbs}
      </aside>
    </section>
  );
}
