export function byId(items: Array<any>): {[key:number]:any} {
    return items.reduce((byId: {[index: number]:any}, item: any) => {
        byId[item.id] = item
        return byId
    }, {})
}

export function parentIds(items: Array<any>): Array<number> {
    return items.filter(item => item.parent_item === null).map((item: any) => {
        return item.id
    })
}

export function idsByCategory(items: Array<any>): {[key:string]:Array<number>} {
    return items.reduce((byCategory: {[index: string]:Array<number>}, item: any) => {
        (byCategory[item.category] = byCategory[item.category] || []).push(item.id)
        return byCategory
    }, {})
}

export function idsBySubCategory(items: Array<any>): {[key:string]:Array<number>} {
    return items.reduce((bySubCategory: {[index: string]:Array<number>}, item: any) => {
        (bySubCategory[item.sub_category] = bySubCategory[item.sub_category] || []).push(item.id)
        return bySubCategory
    }, {})
}

//TODO: this has not been tested but it should replace idsByCategory as a generic function
export function idsByField(items: Array<any>, field: string): {[key:string]:Array<number>} {
    return items.reduce((byField: {[index: string]:Array<number>}, item: any) => {
        (byField[item[field]] = byField[item[field]] || []).push(item.id)
        return byField
    }, {})
}

// Given an array of objects it returns a unique list of values of a field in the objects
export function uniquFieldValues(items: Array<any>, field: string): Array<number> {
    return Object.keys(idsByField(items, field)).map(Number)
}