import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { RootState } from 'app/rootReducer'

import { fetchPreviews } from './previewsSlice'
import {fetchBasket} from "features/basket/basketSlice";

import { useParams } from "react-router-dom";
import { PreviewsList } from 'components/PreviewsList'

export const PreviewsPage = () => {
    let { id: jobUUID } = useParams();
    const dispatch = useDispatch();

    const {
        ids,
        entities,
        isLoading,
        error: previewsError,
    } = useSelector((state: RootState) => state.previews)

    const {arrangementIds} = useSelector((state: RootState) => state.basket)

    useEffect(() => {
        dispatch(fetchPreviews(jobUUID))
        dispatch(fetchBasket())
    }, [dispatch])

    if (previewsError) {
        return (
            <div>
                <h1>Something went wrong...</h1>
                <div>{previewsError.toString()}</div>
            </div>
        )
    }

    let renderedList = isLoading ? (
        <h3>Loading...</h3>
    ) : (
        <PreviewsList
            ids={ids}
            entities={entities}
            arrangementIds={arrangementIds}
        />
    )

    return (
        <>{renderedList}</>
    )
}
