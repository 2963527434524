import { createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getPreviews, Preview, JobPreviews} from 'api/githubAPI'
import { AppThunk } from '../../app/store'
import {LoadingState, loadingInitialState, startLoading, loadingFailed} from 'utils/loadingWithError'
import {byId} from 'utils/idFunctions'

export interface PreviewsState extends LoadingState {
  ids: Array<number>,
  entities: {[key: number]:Preview},
}

const previewsInitialState: PreviewsState = {
  ids: [],
  entities: {},
  ...loadingInitialState
}


const previews = createSlice({
  name: 'previews',
  initialState: previewsInitialState,
  reducers: {
    getPreviewsStart: startLoading,
    getPreviewsSuccess(state, { payload }: PayloadAction<JobPreviews>) {
      const { preview_images } = payload;
      state.isLoading = false;
      state.error = null;

      const previewsById = byId(preview_images);
      state.entities =previewsById;
      state.ids = Object.keys(previewsById).map(Number)
    },
    getPreviewsFailure: loadingFailed
  }
})

export const {
  getPreviewsStart,
  getPreviewsSuccess,
  getPreviewsFailure,

} = previews.actions

export default previews.reducer


export const fetchPreviews = (uuid: string): AppThunk => async dispatch => {
  try {
    dispatch(getPreviewsStart())
    const jobPreviews = await getPreviews(uuid)
    dispatch(getPreviewsSuccess(jobPreviews))
  } catch (err) {
    dispatch(getPreviewsFailure(err.toString()))
  }
}
