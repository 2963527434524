import React from 'react'
import { useSelector} from 'react-redux'
import { RootState } from 'app/rootReducer'


export const EditorDisplay = () => {
  const {
    items,
    selectedGeoms,
    editor_config: {parent_sub_category}
  } = useSelector((state: RootState) => state.editor)

  let editorDisplay = items === null ? (
    <h3>Select an item to begin</h3>
  ) : (
    <div>
      <h3>Items</h3>
      {
        items.map((item, index) => {
          if(items.length === 1) {
            return (
                <img key={index} src={"/assets/rhs_imgs/" +`c1_g${item.geom}_m${item.material}.png`} />
            )
          } else {
            if(item['geomSubCategory'] === parent_sub_category) {
              return (
                  <img style={{'position': 'absolute'}} key={index} src={"/assets/rhs_imgs/" +`c1_g${item.geom}_m${item.material}.png`} />
              )
            } else {
              return (
                  <img style={{'position': 'relative'}} key={index} src={"/assets/rhs_imgs/" +`c2_pg${selectedGeoms[parent_sub_category]}_g${item.geom}_m${item.material}.png`} />
              )
            }
          }
        }
        )
      }
    </div>
  )

  return (
    <div id="issue-list-page">
      {editorDisplay}
    </div>
  )
}
