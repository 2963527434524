import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'app/store'
import {
  postUserCreate,
  getCurrentUser,
  postUserLogin,
  postUserLogout,
  postUserResendActivation,
  postRequestPasswordReset,
  postPasswordReset,
  postAddEmail,
  postConfirmEmail} from 'api/githubAPI'
import {LoadingState, loadingInitialState, startLoading, loadingFailed} from 'utils/loadingWithError'
import Cookies from 'js-cookie';

export interface Auth {
  id: number | null
  email: string | null
  authenticated: boolean
  guest: boolean | null
  auth_token: string | null
  email_confirmed: boolean | null
}

interface AuthState extends LoadingState, Auth{}

const authInitialState: AuthState = {
  id: null,
  email: null,
  authenticated: false,
  guest: null,
  auth_token: null,
  email_confirmed: null,
  ...loadingInitialState
}

const auth = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    getAuthStart: startLoading,
    getAuthSuccess(state, { payload }: PayloadAction<Auth>) {
      const { id, email, auth_token, guest } = payload
      state.isLoading = false;
      state.error = null;
      state.authenticated = true;
      state.guest = guest;

      state.auth_token = auth_token;
      state.id = id;
      state.email = email;

      // store token in cookie
      if( auth_token ) {
        Cookies.set('tkn', auth_token, { expires: 365,  sameSite: 'Lax'  })
        Cookies.remove('sessionid')
        Cookies.remove('csrftoken')
      }
    },
    logoutSuccess(state) {
      state.isLoading = false
      state.error = null
      state.authenticated = false

      state.id = null
      state.email = null
      state.auth_token = null
      state.guest = null

      Cookies.remove('tkn')
      Cookies.remove('sessionid')
      Cookies.remove('csrftoken')
    },
    getAuthFailure: loadingFailed,
    requestSuccess(state) {
      state.isLoading = false
      state.error = null
    },
    confirmEmailSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.email_confirmed = true;
    },
  }
})

export const {
  getAuthStart,
  getAuthSuccess,
  logoutSuccess,
  getAuthFailure,
  requestSuccess,
  confirmEmailSuccess
} = auth.actions

export default auth.reducer

export const fetchCurrentUser = (): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    const auth = await getCurrentUser()
    dispatch(getAuthSuccess(auth))
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const createUser = (email: string, password: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    const auth = await postUserCreate(email, password)
    dispatch(getAuthSuccess(auth))
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const login = (email: string, password: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    const token = await postUserLogin(email, password)
    dispatch(getAuthSuccess({'email': email, 'auth_token': token, id: null, authenticated: true, guest: false, email_confirmed: null}))
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const logout = (): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    await postUserLogout()
    dispatch(logoutSuccess())
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const resendActivation = (email: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    await postUserResendActivation(email)
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const requestPasswordReset = (email: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    await postRequestPasswordReset(email)
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const resetPassword = (new_password: string, uid: string, token: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    await postPasswordReset(new_password, uid, token)
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const addEmail = (email: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    await postAddEmail(email)
    dispatch(requestSuccess())
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}

export const confirmEmail = (key: string): AppThunk => async dispatch => {
  try {
    dispatch(getAuthStart())
    await postConfirmEmail(key)
    dispatch(confirmEmailSuccess())
  } catch (err) {
    dispatch(getAuthFailure(err.toString()))
  }
}








