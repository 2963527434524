import React, {useState} from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux'
import {login} from 'features/auth/authSlice'
import Col from 'react-bootstrap/Col'


export const LoginForm = () => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    };


    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        dispatch(login(values.email, values.password))
        e.preventDefault()
    };

    return (
        <div style={{'border': '1px solid #666', 'padding': '6px'}}>
            <Form onSubmit={e => onSubmit(e)}>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control required={true} type="email" name="email" onChange={onChange} placeholder="Enter email" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" type="password" onChange={onChange} placeholder="Password" />
                    </Form.Group>
                </Form.Row>
                <Button variant="primary" type="submit">
                    Log in
                </Button>
            </Form>
        </div>
    )
}