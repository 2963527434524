import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import {Preview} from 'api/githubAPI'


type Props = {
    maxTries: number,
    delay: number,
    preview: Preview,
}

export const PollingImageAvailable = ({
    maxTries,
    delay,
    preview: {preview_image: url, has_preview, background_type, background_colour_rgba}
    }: Props) => {
    let remainingAttempts = 0 // maxTries
    const [loaded, setLoaded] = useState(has_preview);
    let intervalTimer: number = 0

    async function checkImageAvailability(url: string) {
        let apiRes = {status: 0}
        try {
            apiRes = await axios.head(url, {baseURL: '/'});
        } catch(err) {
            apiRes.status = err.response.status
        } finally {
            remainingAttempts--
        }
        if( [200, 304].includes(apiRes.status)){
            setLoaded(true)
            clearInterval(intervalTimer)
        }
        if(remainingAttempts < 1){
            clearInterval(intervalTimer)
        }
    }

    useEffect(() => {
        if( has_preview !== true) {
            intervalTimer = setInterval(checkImageAvailability, delay, url);
        }
    }, [])

    const colour = background_type === 'COL' ? `rgba(${background_colour_rgba.join()})` : 'none';


    let renderedImage = loaded ? (
        <div style={{'width': '100%', 'backgroundColor': colour}}>
            <img src={url} style={{'width': '100%'}}/>
        </div>
    ) : (
        null
    )

    return (
        <div id="issue-list-page">
            {renderedImage}
        </div>
    )
}