import Card from 'react-bootstrap/Card'
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import {Preview} from "../api/githubAPI";
import CardDeck from "react-bootstrap/CardDeck";
import { RGBColor } from 'react-color';
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import ColorPicker from "./ColorPicker";

type Props = {
    entity: Preview,
    show: boolean,
    setShow: (show: boolean) => void
}

const BACKGROUND_TYPE: {[key: string]: string} = {
    'NON': 'Transparent Background',
    'COL': 'Background Color',
    'SVG': 'Background Pattern',
    'IMG': 'Background Image',
}

const SVG_OPTIONS: Array<string> = ['Radial Gradient', 'Small Zig-Zag Horizontal', "Diamonds"]


const getSVGBackground = (width: number, height: number, key: string, params: {'svg': string, 'color1': RGBColor, 'color2': RGBColor}) => {

    const color1 = `rgba(${params.color1.r}, ${params.color1.g}, ${params.color1.b}, 255)`;
    const color2 = `rgba(${params.color2.r}, ${params.color2.g}, ${params.color2.b}, 255)`;

    const BACKGROUND_SVG_OPTIONS: {[key: string]: string} = {
        'Radial Gradient':
        `<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 ${width} ${height}">` +
            `<defs>` +
                `<radialGradient id="grad1" cx="${Math.floor(width/2)}" cy="${Math.floor(height/2)}" r="50%" gradientUnits="userSpaceOnUse">` +
                    `<stop offset="0" stop-color="${color1}"/>` +
                    `<stop offset="1" stop-color="${color2}"/>` +
                `</radialGradient>` +
            `</defs>` +
            `<rect fill="url(#grad1)" width="${width}" height="${height}"/>` +
        `</svg>`,

        'Small Zig-Zag Horizontal':
        `<svg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'>` +
            `<rect fill='${color1}' width="20" height="12"/>` +
            `<g fill-rule='evenodd'>` +
                `<g id='small-horz-zig-zag' fill='${color2}'>` +
                    `<path d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/>` +
                `</g>` +
            `</g>` +
        `</svg>`,

        'Diamonds':
        `<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'>` +
            `<g>` +
              `<g>` +
               `<rect fill="${color1}" height="100" width="100" y="0" x="0"/>` +
              `</g>` +
              `<g fill-rule='evenodd'>` +
               `<path stroke="${color2}" d="m0,50l50,-50l50,50l-50,50l-50,-50z" stroke-width="0.1" fill="${color2}"/>` +
              `</g>` +
             `</g>`+
            `</svg>`
    }

    //console.log(BACKGROUND_SVG_OPTIONS[key])

    return escape(BACKGROUND_SVG_OPTIONS[key])
}

const BACKGROUND_IMG_OPTIONS: {[key: string]: string} = {
    'Spring in the garden': '/assets/background_images/2rx7bNHfxPbTfRmgBqnua9.jpg',
    'Summer theme': '/assets/background_images/8pmHjAnNmx8egPUXGKssuJ.jpg',
}

export const EditArrangementModal = ({entity, show, setShow}: Props) => {
    const transformEntity = (entity: Preview) => {
        const initialColor = entity.background_colour_rgba ?
            {'r': entity.background_colour_rgba[0], 'g': entity.background_colour_rgba[1], 'b': entity.background_colour_rgba[2]}
            : {'r': 255, 'g': 255, 'b': 255}

        const initialBackgroundParameters = entity.background_parameters ? entity.background_parameters :
            {
                'svg': SVG_OPTIONS[0],
                'color1': {'r': 255,'g': 255,'b': 255,'a': 255},
                'color2': {'r': 255,'g': 0,'b': 0,'a': 255}
            }

        const initialBackgrounImage = entity.background_image ? entity.background_image : Object.values(BACKGROUND_IMG_OPTIONS)[0]

        return {
            ...entity,
            'background_colour_rgba': initialColor,
            'background_parameters': initialBackgroundParameters,
            'background_image': initialBackgrounImage,
        }
    }


    useEffect(() => {
        if(entity.id !== entityCopy.id){
            updateEntityCopy(
                transformEntity(entity)
            )
        }
    }, [entity])

    const [entityCopy, updateEntityCopy] = useState(
        transformEntity(entity)
    )

    // see documentation https://casesandberg.github.io/react-color/
    const handleChangeComplete = (id: string, color: RGBColor) => {
        if( id == 'bg') {
            updateEntityCopy({
                ...entityCopy,
                'background_colour_rgba': color
            })
        } else {
            updateEntityCopy({
                ...entityCopy,
                background_parameters: {...entityCopy.background_parameters, [id]: color}
            })
        }

    };

    const handleSVGChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateEntityCopy({
            ...entityCopy,
            background_parameters: {...entityCopy.background_parameters, [e.target.name]: e.target.value}
        })
    };

    const handleBGImgChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateEntityCopy({
            ...entityCopy,
            background_image: BACKGROUND_IMG_OPTIONS[e.target.value]
        })
    };

    const backgroundDisplay = () => {
        switch (entityCopy.background_type) {
            case 'NON':
                return {}
                break;
            case 'COL':
                return {
                    'backgroundColor': `rgba(${entityCopy.background_colour_rgba.r}, ${entityCopy.background_colour_rgba.g}, ${entityCopy.background_colour_rgba.b},255)`
                }
                break;
            case 'SVG':
                return {
                    'backgroundImage': `url("data:image/svg+xml,${getSVGBackground(456, 456, entityCopy.background_parameters.svg, entityCopy.background_parameters)}")`
                }
                break;
            case 'IMG':
                return {
                    'backgroundImage': `url(${entityCopy.background_image})`
                }
                break;
            default:
                console.error(`Background option not ${entityCopy.background_type} found`)
        }
    }

    const handleClose = () => setShow(false);
    const handleSave = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton translate={null}>
                <Modal.Title>Edit Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CardDeck>
                    <Card.Body>
                        <div style={{'width': '100%', ...backgroundDisplay()}}>
                            { entity ? (
                                <img src={entity.preview_image} style={{'width': '100%'}}/>
                            ) : null
                            }

                        </div>
                    </Card.Body>
                </CardDeck>
                <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        { BACKGROUND_TYPE[entityCopy.background_type] }
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => updateEntityCopy({...entityCopy, 'background_type': 'NON'})}>Transparent Background</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateEntityCopy({...entityCopy, 'background_type': 'COL'})}>Background Color</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateEntityCopy({...entityCopy, 'background_type': 'SVG'})}>Background Pattern</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateEntityCopy({...entityCopy, 'background_type': 'IMG'})}>Background Image</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                {
                    entityCopy.background_type === 'COL' &&
                        <ColorPicker
                            title={'Background Color'}
                            id={'bg'}
                            color={entityCopy.background_colour_rgba}
                            handleChangeComplete={handleChangeComplete}
                        />
                }

                {
                    entityCopy.background_type === 'SVG' &&
                    <>
                        <br />
                        <Form.Label>Select pattern</Form.Label>
                        <Form.Control as="select" defaultValue={entityCopy.background_parameters.svg} onChange={handleSVGChange} name={'svg'}>
                            { SVG_OPTIONS.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                                ))
                            }
                        </Form.Control>

                        <ColorPicker
                            title={'Color 1'}
                            id={'color1'}
                            color={entityCopy.background_parameters['color1']}
                            handleChangeComplete={handleChangeComplete}
                        />
                        <ColorPicker
                            title={'Color 2'}
                            id={'color2'}
                            color={entityCopy.background_parameters['color2']}
                            handleChangeComplete={handleChangeComplete}
                        />
                    </>
                }

                {
                    entityCopy.background_type === 'IMG' &&
                    <>
                        <br />
                        <Form.Label>Select pattern</Form.Label>
                        <Form.Control as="select" defaultValue={entityCopy.background_image} onChange={handleBGImgChange} name={'background_image'}>
                            { Object.keys(BACKGROUND_IMG_OPTIONS).map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))
                            }
                        </Form.Control>
                    </>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}