import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Texture } from 'api/githubAPI'
import { AppThunk } from 'app/store'
import {postTexture, getTextures} from 'api/githubAPI'
import {LoadingState, loadingInitialState, startLoading, loadingFailed} from 'utils/loadingWithError'

interface TexturesState extends LoadingState{
  textures: Array<Texture>
}

const texturesInitialState: TexturesState = {
  textures: [],
  ...loadingInitialState
}

const textures = createSlice({
  name: 'textures',
  initialState: texturesInitialState,
  reducers: {
    getTexturesStart: startLoading,
    getTexturesSuccess(state, { payload }: PayloadAction< Texture[]>) {
      state.isLoading = false
      state.error = null
      state.textures = payload
    },
    postTexturesSuccess(state, { payload }: PayloadAction<Texture>) {
      state.textures.push(payload)
    },
    getTexturesFailure: loadingFailed
  }
})

export const {
  getTexturesStart,
  getTexturesSuccess,
  postTexturesSuccess,
  getTexturesFailure
} = textures.actions

export default textures.reducer


export const createTexture = (textureData: FormData): AppThunk => async dispatch => {
  try {
    dispatch(getTexturesStart())
    const newTexture = await postTexture(textureData)
    dispatch(postTexturesSuccess(newTexture))
  } catch (err) {
    dispatch(getTexturesFailure(err.toString()))
  }
}

export const fetchTextures = (): AppThunk => async dispatch => {
  try {
    dispatch(getTexturesStart())
    const textures = await getTextures()
    dispatch(getTexturesSuccess(textures))
  } catch (err) {
    dispatch(getTexturesFailure(err.toString()))
  }
}