import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Item, Geom, Material, AssetsResult, getAssets, EditorConfig } from 'api/githubAPI'
import { AppThunk } from 'app/store'
import {LoadingState, loadingInitialState, startLoading, loadingFailed} from 'utils/loadingWithError'
import {byId, parentIds, idsBySubCategory} from 'utils/idFunctions'
import {setEditorConfig} from 'features/editor/editorSlice'

interface AssetsState extends LoadingState {
  items: {
    ids: Array<number>,
    entities: {[key: number]:Item},
    parentIds: Array<number>
  },
  geoms: {
    ids: Array<number>,
    entities: {[key: number]:Geom},
    idsBySubCategory: {[index:string]:Array<number>}
    },
  materials: {
    ids: Array<number>,
    entities: {[key: number]:Material}
    }
}

const assetsInitialState: AssetsState = {
  items: { ids: [], entities: {}, parentIds: [] },
  geoms: { ids: [], entities: {}, idsBySubCategory: {} },
  materials: { ids: [], entities: {} },
  ...loadingInitialState
}

const assets = createSlice({
  name: 'assets',
  initialState: assetsInitialState,
  reducers: {
    getAssetsStart: startLoading,
    getAssetsSuccess(state, { payload }: PayloadAction<AssetsResult>) {
      const { items, geoms, materials } = payload
      state.isLoading = false
      state.error = null

      const itemsById = byId(items)
      const parentItemIds = parentIds(items)
      state.items = {
        entities: itemsById,
        ids: Object.keys(itemsById).map(Number),
        parentIds: parentItemIds
      }

      const geomsById = byId(geoms)
      state.geoms = {
        entities: geomsById,
        ids: Object.keys(geomsById).map(Number),
        idsBySubCategory: idsBySubCategory(geoms),
      }

      const materialsById = byId(materials)
      state.materials = {entities: materialsById, ids: Object.keys(materialsById).map(Number)}
    },
    getAssetsFailure: loadingFailed
  }
})

export const {
  getAssetsStart,
  getAssetsSuccess,
  getAssetsFailure
} = assets.actions

export default assets.reducer

export const fetchAssets = (category: string): AppThunk => async dispatch => {
  try {
    dispatch(getAssetsStart())
    const assets = await getAssets(category)
    dispatch(getAssetsSuccess(assets))
    dispatch(setEditorConfig(assets.editor_config))
  } catch (err) {
    dispatch(getAssetsFailure(err.toString()))
  }
}
