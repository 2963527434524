import React from 'react'

import { Geom } from 'api/githubAPI'
import { GeomsDetails } from './GeomsDetails'
import { setGeom } from '../../editor/editorSlice'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from "../../../app/rootReducer";


interface Props {
  geoms: {
    ids: number[]
    entities: { [key: number]: Geom }
    idsBySubCategory: {[index:string]:Array<number>}
  },
  selectedGeoms: {[index:string]:number}
}

export const GeomsList = ({ geoms: { ids, entities, idsBySubCategory }, selectedGeoms }: Props) => {
  const dispatch = useDispatch()

  const {
    editor_config: {sections}
  } = useSelector((state: RootState) => state.editor)

  const setEditorGeom = (category: string, id: number) => {
    dispatch(setGeom({geomSubCategory: category, geom: id}))
  }

  const renderedItems = sections.map(section => (
    <div key={section.sub_category}>
      <h4>{section.heading} Models</h4>
      <div className="card-group no-gutters">
        {
          idsBySubCategory[section.sub_category].map(id => (
            <div className="col-sm-3" key={id} onClick={() => setEditorGeom(section.sub_category, id)}>
              <GeomsDetails selected={selectedGeoms[section.sub_category] === id} {...entities[id]} />
            </div>
          ))
        }
      </div>
    </div>
  ))

  return <div>
    <h3>Models</h3>
    {renderedItems}
  </div>
}
