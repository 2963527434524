import React from 'react'

import { Material } from 'api/githubAPI'
import { MaterialDetails } from './MaterialDetails'
import {useDispatch, useSelector} from 'react-redux'
import { setMaterial } from '../../editor/editorSlice'
import {RootState} from "../../../app/rootReducer";


interface Props {
  materials: {
    ids: number[]
    entities: { [key: number]: Material }
  },
  selectedMaterials: {[index:string]:number}
}

export const MaterialsList = ({ materials: { ids, entities }, selectedMaterials }: Props) => {
  const dispatch = useDispatch()

  const {
    editor_config: {sections}
  } = useSelector((state: RootState) => state.editor)

  const setEditorMaterial = (subCategory: string, id: number) => {
    dispatch(setMaterial({geomSubCategory: subCategory, material: id}))
  }

  const renderedItems = sections.map(section => (
      <div key={section.sub_category}>
        <h4>{section.heading} Materials</h4>
        <div className="card-group no-gutters">
          {
            ids.map(id => {
              if (entities[id].sub_category.includes(section.sub_category)) {
                return (
                    <div className="col-sm-3" key={id}
                         onClick={() => setEditorMaterial(section.sub_category, id)}>
                      <MaterialDetails
                          selected={selectedMaterials[section.sub_category] === id} {...entities[id]} />
                    </div>
                )
              }
            })
          }
        </div>
      </div>
  ))

  return <div>
    <h3>Materials</h3>
    {renderedItems}
  </div>
}
