import React from 'react'

import { AssetsList } from 'features/assetsList/AssetsList'
import { EditorDisplay } from 'features/editor/EditorDisplay'
import { TextureImageForm  } from 'features/textureImageForm/TextureImageForm.js'
import {PreviewButton} from 'features/editor/PreviewButton'
import {PreviewsPage} from 'features/previews/PreviewsPage'
import {BasketPage} from 'features/basket/BasketPage'
import {LoginForm} from 'components/LoginForm'
import {AuthStatus} from 'components/AuthStatus'
import {CreateUserForm} from 'components/CreateUserForm'
import {LogoutForm} from 'components/LogoutForm'
import {ResendActivateForm} from 'components/ResendActivateForm'
import {RequestPasswordResetForm} from 'components/RequestPasswordResetForm'
import {PasswordResetForm} from 'components/PasswordResetForm'
import {GuestEmailForm} from 'components/GuestEmailForm'
import {ConfirmEmailForm} from 'components/ConfirmEmailForm'
import {SocialLoginForm} from 'components/SocialLoginForm'
import {PaymentForm} from 'features/payment/PaymentForm'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import './App.css'

const App: React.FC = () => {
  let content = (
      <Router>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/home">Navbar</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/editor">Editor</Nav.Link>
            <Nav.Link href="/basket">Basket</Nav.Link>
            <Nav.Link href="/features">Features</Nav.Link>
            <Nav.Link href="/pricing">Pricing</Nav.Link>
            <Nav.Link href="/auth">Authentication</Nav.Link>
          </Nav>
          <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-info">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>

        <Switch>
          <Route path="/previews/:id">
            <PreviewsPage />
          </Route>
          <Route path="/basket/">
            <BasketPage />
            <br />
            <PaymentForm />
          </Route>
          <Route path={"/pricing/"}>
            <PaymentForm />
          </Route>
          <Route path="/password/reset/confirm/:uid/:token">
            <PasswordResetForm />
          </Route>
          <Route path="/confirm-email/:key">
            <ConfirmEmailForm />
          </Route>
          <Route path="/editor/:category">
            <div className="row">
              <div className="col-sm-8 order-2 order-sm-1">
                <AssetsList />
              </div>
              <div className="col-sm-4 order-1 order-sm-2">
                <EditorDisplay />
                <TextureImageForm  />
                <PreviewButton />
              </div>
            </div>
          </Route>
          <Route path="/editor/">
            <ul>
              <li><Nav.Link href="/editor/drink-can">Drink Cans</Nav.Link></li>
              <li><Nav.Link href="/editor/med-bottle">Nutritional Supplements/Medicine Bottles</Nav.Link></li>
              <li><Nav.Link href="/editor/wine-bottle">Wine Bottles</Nav.Link></li>
            </ul>
          </Route>
          <Route path="/">
            <h1>Render Fox</h1>
            <ul>
              <li><Nav.Link href="/editor/drink-can">Drink Cans</Nav.Link></li>
              <li><Nav.Link href="/editor/med-bottle">Nutritional Supplements/Medicine Bottles</Nav.Link></li>
              <li><Nav.Link href="/editor/wine-bottle">Wine Bottles</Nav.Link></li>
            </ul>
          </Route>
        </Switch>
      </Router>
  )

  return <Container className="p-3">{content}</Container>
}

export default App
