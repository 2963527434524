import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'
import {postPreview, Item, EditorConfig} from 'api/githubAPI'
import store, { AppThunk } from '../../app/store'
import {loadingInitialState, LoadingState} from "../../utils/loadingWithError";

export interface itemKeys {
  geomCategory: string
  geomSubCategory: string
  geom: number
  material: number
  previewImage: string
}

export interface EditorState extends LoadingState {
  items: Array<itemKeys>
  selectedItemId: number | null
  selectedGeoms: {[index:string]:number}
  selectedMaterials: {[index:string]:number}
  selectedTextureId: number | null
  readyForPreview: boolean
  createdJobUUID: string | null
  editor_config: EditorConfig
}

const editorInitialState: EditorState = {
  items: [],
  selectedItemId: null,
  selectedGeoms: {},
  selectedMaterials: {},
  selectedTextureId: null,
  readyForPreview: false,
  createdJobUUID: null,
  editor_config: {parent_sub_category: '', editor_heading: '', sections: []},
  ...loadingInitialState
}

export interface CreatePreviewsResult {
  job_uuid: string
}

const readyForPreview = (items: itemKeys[], selectedTextureId: number | null, editor_config: EditorConfig) => {
  return items.length == editor_config.sections.length && selectedTextureId !== null
}

function startLoading(state: EditorState) {
  state.isLoading = true
}

function loadingFailed(state: EditorState, action: PayloadAction<string>) {
  state.isLoading = false
  state.error = action.payload
}


const editor = createSlice({
  name: 'editor',
  initialState: editorInitialState,
  reducers: {
    setItem(state, action: PayloadAction<{ items: Array<itemKeys>, selectedItemId: number }>) {
      state.items = action.payload.items
      state.selectedItemId = action.payload.selectedItemId
      state.selectedGeoms = action.payload.items.reduce((acc: {[index: string]:number}, item: itemKeys) => {
        acc[item.geomSubCategory] = item.geom
        return acc
      }, {})
      state.selectedMaterials = action.payload.items.reduce((acc: {[index: string]:number}, item: itemKeys) => {
        acc[item.geomSubCategory] = item.material
        return acc
      }, {})
      state.readyForPreview = readyForPreview(state.items, state.selectedTextureId, state.editor_config)
    },
    setGeom(state, action: PayloadAction<{ geomSubCategory: string, geom: number }>) {
      state.items = state.items.map((item: itemKeys) => {
        if(item.geomSubCategory === action.payload.geomSubCategory) {
          item.geom = action.payload.geom
        }
        return item
      })
      state.selectedItemId = null
      state.selectedGeoms = {...state.selectedGeoms, [action.payload.geomSubCategory]: action.payload.geom}
      state.readyForPreview = readyForPreview(state.items, state.selectedTextureId, state.editor_config)
    },
    setMaterial(state, action: PayloadAction<{ geomSubCategory: string, material: number }>) {
      state.items = state.items.map((item: itemKeys) => {
        if(item.geomSubCategory === action.payload.geomSubCategory) {
          item.material = action.payload.material
        }
        return item
      })
      state.selectedItemId = null
      state.selectedMaterials = {...state.selectedMaterials, [action.payload.geomSubCategory]: action.payload.material}
      state.readyForPreview = readyForPreview(state.items, state.selectedTextureId, state.editor_config)
    },
    setTexture(state, action: PayloadAction<number>) {
      state.selectedTextureId = action.payload
      state.readyForPreview = readyForPreview(state.items, state.selectedTextureId, state.editor_config)
    },
    createPreviewsStart: startLoading,
    createPreviewsSuccess(state, { payload }: PayloadAction<CreatePreviewsResult>) {
      const {job_uuid} = payload
      state.createdJobUUID = job_uuid
      state.isLoading = false
      state.error = null
    },
    createPreviewsFailure: loadingFailed,
    setEditorConfig(state, { payload }: PayloadAction<EditorConfig>) {
      state.editor_config = payload
    }
  }
})

export const {
  setGeom,
  setMaterial,
  setTexture,
  createPreviewsStart,
  createPreviewsSuccess,
  createPreviewsFailure,
  setEditorConfig,

} = editor.actions


export const setItem = createAction('editor/setItem', function prepare(item: Item) {
  const { assets: {items: {entities: itemEntities, ids: itemIds}, geoms: {entities: geomEntities}} } = store.getState()

  return {
    payload: {
      selectedItemId: item.id,
      items: [
        {
          geomCategory: geomEntities[item.geom].category,
          geomSubCategory: geomEntities[item.geom].sub_category,
          geom: item.geom,
          material: item.material,
          previewImage: '',
        },
        ...itemIds.reduce((acc: Array<itemKeys>, id: number) => {
          if(itemEntities[id].parent_item === item.id) {
            acc.push({
              geomCategory: geomEntities[itemEntities[id].geom].category,
              geomSubCategory: geomEntities[itemEntities[id].geom].sub_category,
              geom: itemEntities[id].geom,
              material: itemEntities[id].material,
              previewImage: '',
            })
          }
          return acc
        }, []),
      ],
      /*id: nanoid(),
      createdAt: new Date().toISOString(),*/
    },
  }
})

export default editor.reducer


export const createPreviews = (editorForm: EditorState, history: any): AppThunk => async dispatch => {
  try {
    dispatch(createPreviewsStart());
    const jobUUID = await postPreview(editorForm);
    history.push(`/previews/${jobUUID.job_uuid}`);
    dispatch(createPreviewsSuccess(jobUUID))
  } catch (err) {
  dispatch(createPreviewsFailure(err.toString()))
  }
}
