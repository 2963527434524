import React, {useState} from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useDispatch } from 'react-redux'
import {resetPassword} from 'features/auth/authSlice'
import Col from 'react-bootstrap/Col'
import {useParams} from "react-router";


export const PasswordResetForm = () => {
    let { uid, token  } = useParams();
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        password: "",
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        })
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        dispatch(resetPassword(values.password, uid, token));
        e.preventDefault()
    };

    return (
        <div style={{'border': '1px solid #666', 'padding': '6px'}}>
            <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Row>
                    <Form.Group as={Col} controlId="formBasicEmail">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control name="password" type="password" onChange={onChange} placeholder="Password" />
                    </Form.Group>
                </Form.Row>
                <Button variant="primary" type="submit">
                    Set Password
                </Button>
            </Form>
        </div>
    )
}