import {PayloadAction} from "@reduxjs/toolkit";

export interface LoadingState {
    isLoading: boolean
    error: string | null
}

export const loadingInitialState: LoadingState = {
    isLoading: false,
    error: null
}

export function startLoading(state: LoadingState) {
    state.isLoading = true
}

export function loadingFailed(state: LoadingState, action: PayloadAction<string>) {
    state.isLoading = false
    state.error = action.payload
}