import Card from 'react-bootstrap/Card'
import {PollingImageAvailable} from "../features/previews/PollingImageAvailable";
import {currency} from "../utils/formatters";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {Preview} from "../api/githubAPI";
import { addToBasket, removeFromBasket } from 'features/basket/basketSlice'
import {useDispatch} from "react-redux";
import CardDeck from "react-bootstrap/CardDeck";
import {Row, Col} from "react-bootstrap";
import { RGBColor } from 'react-color';
import { EditArrangementModal } from 'components/EditArrangementModal'

type Props = {
    ids: Array<number>,
    entities: {[key: number]:Preview},
    arrangementIds: number[]
}


export const PreviewsList = ({ids, entities, arrangementIds}: Props) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState(
            0
    );

    const addItemInBasket = (id: number) => dispatch(addToBasket(id))
    const removeItemFromBasket = (id: number) => dispatch(removeFromBasket(id))

    const selectArrangementToEdit = (id: number) => {
        setEditId(id);
        setShow(true)
    }

    return (
        <>
            <CardDeck>
                {ids.map(id => (
                    <Row xs={2} md={4} className="g-4">
                        <Col>
                        <Card style={{width: '14rem'}} key={id}>
                                <Card.Body>
                                    <PollingImageAvailable
                                        maxTries={20}
                                        delay={2000}
                                        preview={entities[id]}/>
                                </Card.Body>
                                <Card.Footer>
                                    <Button variant="success" className="float-right" onClick={() => selectArrangementToEdit(id)}>
                                        Edit image <i className="fas fa-pencil"></i>
                                    </Button>
                                    <div className="float-left">Price: {currency.format(entities[id].price_usd)}</div>
                                    {
                                        arrangementIds.includes(id) ?
                                            <Button variant="warning" className="float-right"
                                                    onClick={() => removeItemFromBasket(id)}>
                                                Remove from cart <i className="fas fa-shopping-cart"></i>
                                            </Button>
                                            :
                                            <Button variant="success" className="float-right" onClick={() => addItemInBasket(id)}>
                                                Add to cart <i className="fas fa-cart-plus"></i>
                                            </Button>
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                ))
                }
            </CardDeck>
            {   editId ?
                <EditArrangementModal
                    show={show}
                    entity={entities[editId]}
                    setShow={setShow}
                /> : null
            }
            </>
    )
}