import axios from 'axios'
import Cookies from 'js-cookie';
import {EditorState, CreatePreviewsResult} from 'features/editor/editorSlice'
import {Basket, BasketItem} from 'features/basket/basketSlice'
import {Auth} from 'features/auth/authSlice'
import {RGBColor} from "react-color";

//https://stackoverflow.com/questions/51794553/how-do-i-create-configuration-for-axios-for-default-request-headers-in-every-htt
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
//axios.defaults.headers.common['Authorization'] = 'Authorization: Token';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const getConfig = (initialHeaders={}, initialParams={}) => {
  let config: {[index:string]:any} = { headers: initialHeaders, params: initialParams};
  if(Cookies.get('tkn')) {
    config['headers']['Authorization'] = `Token ${Cookies.get('tkn')}`
  } else if(Cookies.get('csrftoken')) {
    config['headers']['X-CSRFToken'] = Cookies.get('csrftoken')
  }
  return config
}

export interface AssetsResult {
    items: Item[],
    geoms: Geom[],
    materials: Material[],
    editor_config: EditorConfig
}

export interface Item {
  id: number
  parent_item: number
  geom: number
  material: number
  texture: Texture
  preview_image: string
}

export interface Geom {
  id: number
  display_name: string
  category: string
  sub_category: string
  preview_image: string
}

export interface Material {
  id: number
  display_name: string
  sub_category: string
  preview_image: string
}

export interface Texture {
  id: number
  owner: number
  url: string
}


export interface JobPreviews {
  created: string,
  preview_images: Preview[]
}

export interface Preview {
  id: number
  has_preview: boolean
  preview_image: string
  price_usd: number
  background_type: string
  background_colour_rgba: [number, number, number, number]
  background_parameters: {'svg': string, 'color1': RGBColor, 'color2': RGBColor}
  background_image: string
}


export interface EditorConfig {
  parent_sub_category: string
  editor_heading: string
  sections: Array<{sub_category: string, heading: string}>
}

export async function getAssets(category: string) {
  const url = `https://api2.renderfox.com/api/v1/assets/`

  const { data } = await axios.get<AssetsResult>(url, getConfig({},{category: category}));
  return {
    items: data.items,
    geoms: data.geoms,
    materials: data.materials,
    editor_config: data.editor_config,
  }
}

export async function getTextures() {
  const url = `https://api2.renderfox.com/api/v1/textures/`

  const { data } = await axios.get<Texture[]>(url, getConfig());
  return data
}

export async function postTexture(formData: FormData) {
  const url = `https://api2.renderfox.com/api/v1/textures/`
  const { data } = await axios.post<Texture>(url, formData, getConfig({'Content-Type': 'multipart/form-data'}) );
  return data
}

export async function postPreview(editorForm: EditorState) {
  const url = `https://api2.renderfox.com/api/v1/previews/`
  const { data } = await axios.post<CreatePreviewsResult>(url, editorForm, getConfig() );
  return data
}

export async function getPreviews(job_uuid: string) {
  const url = `https://api2.renderfox.com/api/v1/previews/${job_uuid}`

  const { data } = await axios.get<JobPreviews>(url, getConfig());
  return data
}


export async function getBasket() {
  const url = `https://api2.renderfox.com/api/v1/baskets/`

  const { data } = await axios.get<{items: BasketItem[], total_price: number}>(url, getConfig())
  return data
}

export async function postBasketItem(arrangementId: number) {
  const url = `https://api2.renderfox.com/api/v1/baskets/`
  const { data } = await axios.post<{item: BasketItem, total_price: number}>(url, {arrangement: arrangementId}, getConfig() )
  return data
}

export async function deleteBasketItem(basketItemId: number) {
  const url = `https://api2.renderfox.com/api/v1/baskets/${basketItemId}/`
  const { data } = await axios.delete(url, getConfig());
  return data
}

export async function getCurrentUser() {
  const url = `https://api2.renderfox.com/api/v1/auth/users/me/`
  const { data } = await axios.get<Auth>(url, getConfig());
  return data
}

export async function postUserCreate(email: string, password: string) {
  const url = `https://api2.renderfox.com/api/v1/auth/users/`
  const { data } = await axios.post<Auth>(url, {email: email, password: password}, getConfig());
  return data
}

export async function postUserLogin(email: string, password: string) {
  const url = `https://api2.renderfox.com/api/v1/auth/token/login/`
  const { data } = await axios.post<{auth_token: string}>(url, {email: email, password: password}, getConfig());
  return data.auth_token
}

export async function postUserLogout() {
  const url = `https://api2.renderfox.com/api/v1/auth/token/logout/`
  const { data } = await axios.post<{}>(url, {}, getConfig());
  return data
}

export async function postUserResendActivation(email: string) {
  const url = `https://api2.renderfox.com/api/v1/auth/users/resend_activation/`
  const { data } = await axios.post<{}>(url, {email: email}, getConfig());
  return data
}

export async function postRequestPasswordReset(email: string) {
  const url = `https://api2.renderfox.com/api/v1/auth/users/reset_password/`
  const { data } = await axios.post<{}>(url, {email: email}, getConfig());
  return data
}

export async function postPasswordReset(new_password: string, uid: string, token: string) {
  const url = `https://api2.renderfox.com/api/v1/auth/users/reset_password_confirm/`
  const { data } = await axios.post<{}>(url, {new_password: new_password, uid: uid, token: token}, getConfig());
  return data
}

export async function postAddEmail(email: string) {
  const url = `https://api2.renderfox.com/api/v1/add-email/`
  const { data } = await axios.post<{}>(url, {email: email}, getConfig());
  return data
}

export async function postConfirmEmail(key: string) {
  const url = `https://api2.renderfox.com/api/v1/confirm-email/`
  const { data } = await axios.post<{}>(url, {key: key}, getConfig());
  return data
}

export async function payPalCreateOrder() {
  const url = `https://api2.renderfox.com/api/v1/paypal/order/create/`
  const { data } = await axios.post<{id: number}>(url, {}, getConfig());
  return data.id
}

export async function payPalOrderCapture(orderId: number) {
  const url = `https://api2.renderfox.com/api/v1/paypal/order/${orderId}/capture/`
  const { data } = await axios.post<{'details': [{'issue': string, 'description': string}] | null, 'debug_id': {}, 'id': number, 'status': string}>(url, {}, getConfig());
  return data
}
