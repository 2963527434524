import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'app/rootReducer'
import { PayPalButton } from "react-paypal-button-v2";
import { payPalCreateOrder, payPalOrderCapture } from 'api/githubAPI'

import {fetchBasket} from "features/basket/basketSlice";


export const PaymentForm = () => {

    // Taken from https://developer.paypal.com/demo/checkout/#/pattern/server
    // Finalizes the transaction
    const onApprove = (data: any, actions: any) => {
        payPalOrderCapture(data.orderID)
            .then(function(orderData) {
            // Three cases to handle:
            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //   (2) Other non-recoverable errors -> Show a failure message
            //   (3) Successful transaction -> Show a success / thank you message

            // Your server defines the structure of 'orderData', which may differ
            var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                // Recoverable state, see: "Handle Funding Failures"
                // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                return actions.restart();
            }

            if (errorDetail) {
                var msg = 'Sorry, your transaction could not be processed.';
                if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                // Show a failure message
                return alert(msg);
            }

            // Show a success message to the buyer
            alert('Transaction ' + orderData.status + ' Order id : ' + orderData.id );
        });
    };


    return (
        <PayPalButton
            createOrder={payPalCreateOrder}
            onApprove={onApprove}
            options={{
                clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
            }}
        />
    )
}
