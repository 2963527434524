import React from 'react'

import { Item } from 'api/githubAPI'

type Props = Item & {
  selected: boolean
}

export const ItemDetails = ({
  id,
  geom,
  material,
  parent_item,
  selected,
  preview_image,
}: Props) => {

  const bgColour = ['E56399', 'D3A588', 'ECE2D0', '7FD1B9', '7A6563', 'A5D8FF', 'FFBA08', 'D00000', '4C3B4D', '89B6A5']
  const svgImage = encodeURIComponent('<svg width="239" height="180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239 180" preserveAspectRatio="none"><defs><style type="text/css">#holder_1755f45c145 text { fill:rgba(255,255,255,.75);font-weight:normal;font-family:Helvetica, monospace;font-size:12pt } </style></defs><g id="holder_1755f45c145"><rect width="239" height="180" fill="#' + bgColour[id] + '"></rect><g><text x="87.75" y="95.1">239x180</text></g></g></svg>')
  // src={"data:image/svg+xml;charset=UTF-8," +  svgImage }

  return (
    <div className={`card ${selected ? "border-primary" : ""}`} >
      <img className="card-img-top" data-src="holder.js/100px180/" alt="100%x180"
           src={preview_image }
           data-holder-rendered="true" style={{"width": "100%", "display": "block"}} />
      <div className="card-body">
        <h6 className="card-title">Item #{id}</h6>
          <p className="card-text">
            <small className="text-muted">Geom: {geom} Material: {material} Parent: {parent_item}</small>
          </p>
      </div>
    </div>
  )
}
