import React, {useState} from 'react'
import reactCSS from 'reactcss'
import { SketchPicker, Color, RGBColor, ColorResult } from 'react-color'

type Props = {
    color: RGBColor,
    handleChangeComplete: (id: string, color: RGBColor) => void,
    title: string | null,
    id: string
}

const ColorPicker = ({color, handleChangeComplete, title, id}: Props) => {
    const [state, setState] = useState(
        {
            displayColorPicker: false,
            color: color
        }
    )

    const handleClick = () => {
        setState({ ...state, displayColorPicker: !state.displayColorPicker })
    };

    const handleClose = () => {
        setState({ ...state, displayColorPicker: false })
    };

    const handleChange = (color: {'rgb': RGBColor}) => {
        setState({ ...state, color: color.rgb })
        handleChangeComplete(id, color.rgb)
    };

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                backgroundColor: `rgba(${ state.color.r }, ${ state.color.g }, ${ state.color.b })`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute' as 'absolute',
                zIndex: 2,
            } as React.CSSProperties,
            cover: {
                position: 'fixed' as 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            } as React.CSSProperties,
        },
    });

    return (
            <div>
                <div style={ styles.swatch } onClick={ handleClick }>
                    <div style={ styles.color } />
                </div><span> {title}</span>
                { state.displayColorPicker ? <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ handleClose }/>
                    <SketchPicker color={ state.color } onChange={ handleChange } />
                </div> : null }

            </div>
        )

}

export default ColorPicker